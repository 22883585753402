<!-- Botão para abrir o modal -->
<button class="btn btn-primary" (click)="open(content)">
  Buscar Colaborador
</button>

<!-- Conteúdo do Modal -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Buscar Colaborador</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <form class="form" *ngIf="searchForm" [formGroup]="searchForm">
      <div class="row">
        <div class="col-3">
          <div class="form-group">
            <label for="name">Nome</label>
            <input
              type="text"
              class="form-control"
              placeholder="Nome"
              formControlName="name"
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="name">Cargo</label>
            <input
              type="text"
              class="form-control"
              placeholder="Cargo"
              formControlName="role"
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="name">WhatsApp</label>
            <input
              type="text"
              class="form-control"
              mask="+00 00 0 0000-0000"
              placeholder="Whatsapp"
              formControlName="whatsapp"
            />
          </div>
        </div>
        <div class="col-2">
          <div class="form-group">
            <label for="name">Status</label>
            <select formControlName="active" class="form-control">
              <option value="">Todos</option>
              <option [value]="true">Ativo</option>
              <option [value]="false">Inativo</option>
            </select>
          </div>
        </div>
        <div class="col d-flex">
          <a
            (click)="onSearch()"
            class="btn btn-info align-self-end"
            style="margin-bottom: 15px"
          >
            <i class="fa fa-search"></i>
          </a>
        </div>
      </div>
    </form>
    <div class="table-responsive">
      <table class="table table-striped custom-table table-hover">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Convênio</th>
            <th>Email</th>
            <th>WhatsApp</th>
            <!-- <th>Departamento</th> -->
            <!-- <th>Centro de Custo</th> -->
            <th>Situação</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let collaborator of collaborators">
            <td>
              {{ collaborator.name }}
            </td>
            <td>
              {{ collaborator.covenant.name }}
            </td>

            <td>
              {{ collaborator.email }}
            </td>
            <td>
              {{ collaborator.whatsapp | mask : "+00 00 0 0000-0000" }}
            </td>
            <!-- <td>
                                        {{ collaborator.department }}
                                    </td>
                                    <td>
                                        {{ collaborator.costCenter }}
                                    </td> -->
            <td>
              <span
                class="label label-sucess label-mini"
                *ngIf="collaborator.active"
                >Ativo</span
              >
              <span
                class="label label-danger label-mini"
                *ngIf="!collaborator.active"
                >Inativo</span
              >
            </td>
            <td>
              <span *ngIf="collaborator.active">
                <button
                  (click)="onDeActive(collaborator)"
                  class="btn btn-pink btn-xs"
                >
                  <i class="fa fa-random"></i>
                </button>
              </span>
              <span *ngIf="!collaborator.active">
                <button
                  (click)="onActive(collaborator)"
                  class="btn btn-success btn-xs"
                >
                  <i class="fa fa-random"></i>
                </button>
              </span>
              <span>
                <button
                  (click)="onCollaboratorEdit(collaborator)"
                  class="btn btn-warning btn-xs"
                >
                  <i class="fa fa-pencil"></i>
                </button>
              </span>
              <!-- <button (click)="onCollaboratorDelete(collaborator.id)"
                                            class="btn btn-danger btn-xs">
                                            <i class="fa fa-trash-o "></i>
                                        </button> -->
            </td>
          </tr>
          <tr *ngIf="!collaborators.length">
            <td colspan="6">
              <span class="label label-danger label-mini">
                Nenhum Colaborador adicionado.
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-md-12">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item" [class.disabled]="pagination.page == 1">
              <button class="page-link" (click)="onFirst()" tabindex="-1">
                Primeira
              </button>
            </li>
            <li class="page-item" *ngIf="pagination.page != 1">
              <button (click)="onPrevious()" class="page-link">
                {{ pagination.page - 1 }}
              </button>
            </li>
            <li class="page-item active">
              <button class="page-link">{{ pagination.page }}</button>
            </li>
            <li
              class="page-item"
              *ngIf="
                pagination.page != pagination.totalPages &&
                pagination.page < pagination.totalPages
              "
            >
              <button (click)="onNext()" class="page-link" href="#">
                {{ pagination.page + 1 }}
              </button>
            </li>
            <li
              class="page-item"
              [class.disabled]="
                pagination.page == pagination.totalPages ||
                pagination.totalPages == 0
              "
            >
              <button class="page-link" (click)="onLast()">Última</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-md-12">
        Mostrando página {{ pagination.page }} de
        {{ pagination.totalPages }} dos {{ pagination.totalResults }} Resultados
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="modal.close('close')"
    >
      Voltar
    </button>
  </div>
</ng-template>

<app-modal-covenant-collaborator-form
  [modalFireCondition]="modalFireCondition"
  [covenantId]="collaboratorToEdit?.covenant?.id"
  [collaborator]="collaboratorToEdit"
  (modalClose)="onCloseModalCreateCollaborator($event)"
></app-modal-covenant-collaborator-form>
